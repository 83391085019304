// jQuery
import "jquery/src/jquery.js"

// Popper
import "@popperjs/core/dist/umd/popper.js"

// Bootstrap
import "bootstrap/js/src/alert.js"
import "bootstrap/js/src/base-component.js"
import "bootstrap/js/src/button.js"
//import "bootstrap/js/src/carousel.js"
import "bootstrap/js/src/collapse.js"
import "bootstrap/js/src/dropdown.js"
import "bootstrap/js/src/modal.js"
import "bootstrap/js/src/offcanvas.js"
import "bootstrap/js/src/popover.js"
import "bootstrap/js/src/scrollspy.js"
import "bootstrap/js/src/tab.js"
import "bootstrap/js/src/toast.js"
import "bootstrap/js/src/tooltip.js"
window.bootstrap = require("bootstrap/dist/js/bootstrap.js");
